@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap");
* {
  box-sizing: border-box;
}
body {
  background-color: var(--primary-color1);
}
:root {
  --primary-color1: #112d4e;
  --primary-color2: #bf6330;
  --primary-color3: #e3a924;
  --accent-color1: #ded8b6;
  --accent-color2: #86c1a5;
  --accent-color3: #625185;
  --white: #ffffff;
  --black: #000000;
  --alpha-darken-amount: 0.3;
  --alpha-darken: rgba(0, 0, 0, var(--alpha-darken-amount));
  --logo-font-family: "Raleway", sans-serif;
  --main-font-family: "Nunito Sans", sans-serif;
}

.App {
  font-family: var(--logo-font-family);
  color: var(--accent-color1);
}

a {
  text-decoration: none;
  color: #ded8b6;
}

.navbar {
  height: 50px;
  width: 100%;
  display: inline;
}

.navbar > .navtitle {
  font-family: var(--logo-font-family);
  font-size: 22px;
  padding: 10px 10px 10px 15px;
  display: inline-block;
}

.navbar > .navlinks {
  float: right;
  display: inline;
  font-size: 18px;
}

.navbar > .navlinks > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
}

.navbar > .navlinks > a:hover {
  background-color: var(--alpha-darken);
}

.title {
  text-align: center;
}

.title > .heading {
  font-size: 50px;
}

.title > .subheading {
  font-size: 25px;
}

.homepage-content,
.academia-content,
.music-content,
.software-content,
.games-content {
  display: flex;
  margin: 5% 10% 0% 10%;
}

.software-content,
.games-content {
  flex-wrap: wrap;
  flex-direction: row;
}

.software-element > img,
.games-element > img {
  max-width: 100%;
  border-radius: 2%;
}

.software-element > .software-status-chip {
  margin-bottom: 4%;
}

.software-content > .software-element {
  margin: 1%;
  /* temp color */
  /* background-color: gray; */
  max-width: 23%;
}
.games-content > .games-element {
  margin: 1%;
  /* temp color */
  /* background-color: gray; */
  max-width: 48%;
}

.music-content {
  flex-direction: column;
}

.academia-content {
  height: 60vh;
}

.final-project-pdf {
  width: 100%;
  height: 100%;
}

.homepage-content > .content,
.academia-content > .content {
  padding: 0% 2% 0% 2%;
}

.homepage-content > .profile-pic {
  max-width: 30%;
  height: 50%;
  border-radius: 2%;
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer > .footer-icon {
  font-size: 200%;
  color: var(--accent-color1);
  text-decoration: none;
  margin: 1%;
}

.burger-menu-btn {
  display: none;
}
#nav-check {
  display: none;
}

@media (max-width: 800px) {
  .homepage-content, .academia-content {
    flex-direction: column;
  }
  .homepage-content > .profile-pic {
    max-width: 100%;
  }
  .homepage-content > .content {
    padding: 5% 2% 0% 2%;
  }
  .games-content > .games-element,
  .software-content > .software-element {
    max-width: max-content;
  }

  /* START OF BURGER BAR CSS */
  .burger-menu-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .burger-menu-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .burger-menu-btn > label:hover,
  #nav-check:checked ~ .burger-menu-btn > label {
    background-color: var(--alpha-darken);
  }
  .burger-menu-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid var(--white);
  }
  .navlinks {
    position: absolute;
    display: block;
    width: 100%;
    background-color: var(--primary-color2);
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .navlinks > a {
    display: block;
    background-color: var(--primary-color2);
    width: 100%;
  }
  #nav-check:not(:checked) ~ .navlinks {
    height: 0px;
  }
  #nav-check:checked ~ .navlinks {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
  /* END OF BURGER BAR CSS */
}
